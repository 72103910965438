document.querySelectorAll('.gallery__pagination').forEach(area => {
  area.addEventListener('mouseenter', e => {
    area.querySelector('.gallery__pagination__cursor').style.opacity = 1
  })
  area.addEventListener('mouseleave', e => {
    area.querySelector('.gallery__pagination__cursor').style.opacity = 0
  })
  area.addEventListener('mousemove', e => {
    area.querySelector('.gallery__pagination__cursor').style.left = e.clientX + 'px'
    area.querySelector('.gallery__pagination__cursor').style.top = e.clientY + 'px'
  })
})