import Swiper from "swiper";
import { navigation__init } from "./navigation";
import { navigation__manual__init } from "./navigation-manual";
export let Gallery;

const initSwiper = (el) => {
  return new Promise((resolve) => {
    new Swiper(el, {
      containerModifierClass: "gallery__container-",
      slideClass: "gallery__slide",
      slideActiveClass: "gallery__slide--active",
      slideDuplicateActiveClass: "gallery__slide--duplicate--active",
      slideVisibleClass: "gallery__slide--visible",
      slideDuplicateClass: "gallery__slide--duplicate",
      slideNextClass: "gallery__slide--next",
      slideDuplicateNextClass: "gallery__slide--duplicate--next",
      slidePrevClass: "gallery__slide--prev",
      slideDuplicatePrevClass: "gallery__slide--duplicate--prev",
      wrapperClass: "gallery__wrapper",
      navigation: {
        nextEl: ".gallery__pagination--next",
        prevEl: ".gallery__pagination--prev",
      },
      speed: 0,
      effect: "fade",
      fadeEffect: {
        crossfade: true,
      },
      simulateTouch: false,
      allowTouchMove: false,
      loop: false,
      simulateTouch: false,
      preloadImages: false,
      lazy: true,
      on: {
        init: function () {
          resolve(this);
        },
        lazyImageReady: function (slideEl, imageEl) {
          imageEl.style.transition = "opacity 500ms ease-in-out";
          imageEl.style.opacity = 1;
          imageEl.classList.add("image-loaded");
        },
        transitionEnd: function () {
          const images = this.slides[this.activeIndex].querySelectorAll("img");
          images.forEach((img) => {
            img.style.transition = "opacity 0ms ease-in-out";
            img.style.opacity = 0;
            if (img.classList.contains("image-loaded")) {
              setTimeout(() => {
                // I apologise for this disgusting code.
                img.style.transition = "opacity 500ms ease-in-out";
                img.style.opacity = 1;
              }, 0);
            }
          });
        },
      },
    });
  });
};

const checkForMobile = () => {
  gallery.querySelectorAll(".gallery__slide").forEach((slide) => {
    const images = [...slide.querySelectorAll(".gallery__slide__image")];
    if (images.length > 1) {
      images[0].classList.add("gallery__slide__image--full");
      images[1].classList.add("gallery__slide__image--full");
      const cloneSlide = slide.cloneNode(true);
      images[0].remove();
      [...cloneSlide.querySelectorAll(".gallery__slide__image")][1].remove();
      cloneSlide.dataset.hash = parseInt(slide.dataset.hash) + 1;
      slide.parentNode.insertBefore(cloneSlide, slide.nextSibling);
    }
  });
};

export const changeSlide = (index) => {
  if (Gallery) {
    Gallery.slideTo(index);
  }
};

export const gallery__init = (gallery) => {
  if (window.innerWidth < 768) {
    checkForMobile(gallery);
  }
  initSwiper(gallery).then((swiper) => {
    // navigation__init(document.querySelector('.navigation'), swiper)
    // navigation__manual__init(swiper);
  });
};
