let Gallery;

let slides = [...document.querySelectorAll('.navigation__slide--link')]
let Wrapper = document.querySelector('.navigation__wrapper')
let activeIndex = 0
let startingSlide = slides.length / 3

const checkForMobile = swiper => {
  let slideCount = 1
  slides.forEach(slide => {
    const images = [...slide.querySelectorAll('.navigation__slide__item')]
    if (images.length > 1) {
      const cloneSlide = slide.cloneNode(true)
      images[1].remove()
      ;[...cloneSlide.querySelectorAll('.navigation__slide__item')][0].remove()
      slide.parentNode.insertBefore(cloneSlide, slide.nextSibling);
      cloneSlide.querySelector('button').dataset.slide = slideCount++
      slide.querySelector('button').dataset.slide = slideCount++
    } else {
      slide.querySelector('button').dataset.slide = slideCount++
    }
    if (slideCount >= swiper.slides.length) {
      slideCount = 1
    }
  })
  slides = [...document.querySelectorAll('.navigation__slide--link')]
  startingSlide = slides.length / 3
}


export const navigation__manual__init = swiper => {
  Gallery = swiper


  if (window.innerWidth < 768) {
    checkForMobile(swiper)
  }
  
  slides.forEach((slide, index) => {
    const button = slide.querySelector('button')
    button.dataset.index = index
    button.removeEventListener('click', e => navigationButtonClick(button))
    button.addEventListener('click', e => navigationButtonClick(button))
  })

  activeIndex = startingSlide
  slideNavigation(slides[activeIndex].querySelector('button'))
  document.querySelector('.navigation').classList.add('is-ready')

  document.querySelector('.gallery__pagination--next').addEventListener('click', e => {
    navigationButtonClick(slides[activeIndex + 1].querySelector('button'))
  })
  document.querySelector('.gallery__pagination--prev').addEventListener('click', e => {
    navigationButtonClick(slides[activeIndex - 1].querySelector('button'))
  })

  document.querySelectorAll('.navigation__contact').forEach(contact => {
    contact.classList.add('is-active')
    contact.querySelector('.navigation__contact--title').addEventListener('click', e => {
      const index = contact.parentNode.querySelector('button')
      navigationButtonClick(index)
    })
  })

}


const navigationButtonClick = clickedButton => {
  const slideIndex = parseInt(clickedButton.dataset.slide)
  slideNavigation(clickedButton)
    .then(activeIndex => renderNavigation(activeIndex))
  Gallery.slideToLoop(slideIndex)
}


const slideNavigation = clickedButton => {
  return new Promise(resolve => {
    Wrapper.style.transition = `transform 800ms ease-in-out`
    Wrapper.style.transform = `translateX(-${clickedButton.parentNode.offsetLeft}px)`
    navigationClassCheck(clickedButton)
    activeIndex = parseInt(clickedButton.dataset.index)
    setTimeout(() => {
      resolve(activeIndex)
    }, 800);
  })
}

const renderNavigation = activeIndexCheck => {
  if (activeIndexCheck >= (startingSlide) * 2) {
    activeIndex = activeIndexCheck - startingSlide
    Wrapper.style.transition = `transform 0ms ease-in-out`
    Wrapper.style.transform = `translateX(-${slides[activeIndex].offsetLeft}px)`
  } 

  if (activeIndexCheck === 0) {
    activeIndex = startingSlide
    Wrapper.style.transition = `transform 0ms ease-in-out`
    Wrapper.style.transform = `translateX(-${slides[activeIndex].offsetLeft}px)`
  }
}

const navigationClassCheck = clickedButton => {
  slides.forEach(slide => {
    const button = slide.querySelector('button')
    clickedButton.dataset.slide === button.dataset.slide
      ? button.parentNode.classList.add('navigation__slide--active')
      : button.parentNode.classList.remove('navigation__slide--active')
  })
  document.querySelectorAll('.navigation__contact').forEach(contact => {
    if (clickedButton.dataset.slide == 0) {
      document.querySelectorAll('.navigation__contact').forEach(contact => {
        contact.classList.add('is-active')
      })
    } else {
      document.querySelectorAll('.navigation__contact').forEach(contact => {
        contact.classList.remove('is-active')
      })
    }
  })
}

window.onresize = () => {
  Wrapper.style.transition = `transform 0ms ease-in-out`
  Wrapper.style.transform = `translateX(-${slides[activeIndex].offsetLeft}px)`
}
