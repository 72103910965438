import Swiper from "swiper";

let Navigation;

const checkForMobile = (el) => {
  console.log("it happened");
  el.querySelectorAll(".navigation__slide").forEach((slide) => {
    const images = [...slide.querySelectorAll(".navigation__slide__item")];
    if (images.length > 1) {
      const cloneSlide = slide.cloneNode(true);
      images[1].remove();
      [...cloneSlide.querySelectorAll(".navigation__slide__item")][0].remove();
      slide.parentNode.insertBefore(cloneSlide, slide.nextSibling);
    }
  });
};

export const navigation__init = (el, Gallery) => {
  if (window.innerWidth < 768) {
    checkForMobile(el);
  }

  Navigation = new Swiper(el, {
    containerModifierClass: "navigation-",
    slideClass: "navigation__slide",
    slideActiveClass: "navigation__slide--active",
    slideDuplicateActiveClass: "navigation__slide--duplicate--active",
    slideVisibleClass: "navigation__slide--visible",
    slideDuplicateClass: "navigation__slide--duplicate",
    slideNextClass: "navigation__slide--next",
    slideDuplicateNextClass: "navigation__slide--duplicate--next",
    slidePrevClass: "navigation__slide--prev",
    slideDuplicatePrevClass: "navigation__slide--duplicate--prev",
    wrapperClass: "navigation__wrapper",
    speed: 800,
    simulateTouch: true,
    allowTouchMove: false,
    loop: false,
    spaceBetween: 30,
    loopedSlides: 10,
    // centeredSlides: true,
    normalizeSlideIndex: false,
    // slidesOffsetBefore: 30,
    slidesPerView: "auto",
    keyboard: {
      enabled: true,
    },
    on: {
      init: function () {
        document.querySelector(".navigation").classList.add("is-ready");
        document
          .querySelectorAll(".navigation__slide__btn")
          .forEach((button, index) => {
            button.addEventListener("click", (e) => {
              e.preventDefault();
              const slideTotal = parseInt(button.dataset.length) - 1;
              const slideIndex = parseInt(button.dataset.slide);
              Navigation.slideToLoop(slideIndex);
            });
          });
      },
      slideChange: function () {
        Gallery.slideToLoop(this.realIndex);
      },
    },
  });
};
